// src/App.js
import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "./assets/css/tailwind.css";
import "./assets/css/materialdesignicons.min.css";

import IndexTwo from "./pages/index/index-two";
import Aboutus from "./pages/aboutus";
import Helpcenter from "./pages/helpcenter/helpcenter";
import HelpcenterFaq from "./pages/helpcenter/helpcenter-faqs";
import HelpcenterSupport from "./pages/helpcenter/helpcenter-support";
import Terms from "./pages/utility/terms";
import Privacy from "./pages/utility/privacy";
import Blogs from "./pages/blog/blogs";
import BlogStandard from "./pages/blog/blog-standard";
import BlogDetail from "./pages/blog/blog-detail";
import Contact from "./pages/contact";
import Apply from "./pages/Apply";
import Comingsoon from "./pages/special/comingsoon";
import DynamicTrackingCode from "./components/TrackingCode";
import Portfolio from "./pages/portfolio";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import avatar from "./avatar.PNG"


function App() {
  useEffect(() => {
    const enquiryButton = document.querySelector('.npfWidgetButton');
    if (enquiryButton) {
      enquiryButton.click();
    }
  }, []);

  return (
    <>
    <DynamicTrackingCode />
      <Routes>
        <Route path="/" element={<IndexTwo />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/helpcenter" element={<Helpcenter />} />
        <Route path="/helpcenter-faqs" element={<HelpcenterFaq />} />
        <Route path="/helpcenter-support" element={<HelpcenterSupport />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blog-standard" element={<BlogStandard />} />
        <Route path="/blog-detail" element={<BlogDetail />} />
        <Route path="/blog-detail/:id" element={<BlogDetail />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/apply" element={<Apply />} />
        <Route path="/comingsoon" element={<Comingsoon/>}/>
        <Route path="/portfolio" element={<Portfolio/>}/>
      </Routes>
      <FloatingWhatsApp
        phoneNumber="+263 78 288 4986"
        accountName="Sky Global Education"
        avatar={avatar}
        statusMessage="Typically replies within 1 hour" // Status message below the account name
        chatMessage="Hello! 🌟 How can we assist you with your educational journey?" // Default message in chat box
        placeholder="Type your message here..." // Input placeholder
        messageDelay={2} // Delay in seconds for the chat message to appear
        chatboxHeight={320} // Height of the chat box
        chatboxStyle={{ backgroundColor: "#f0f0f0", color: "#000" }} // Custom styles for the chatbox
        chatboxClassName="custom-chatbox-class" // Custom className for the chatbox
        notification={true} // Enable notifications
        notificationDelay={60} // Delay between notifications in seconds
        notificationLoop={0} // Number of times the notification will loop (0 for infinite)
        allowClickAway={true} // Close chatbox when clicked outside
        allowEsc={true} // Close chatbox when Escape key is pressed
        darkMode={false} // Disable dark mode for a lighter theme
        style={{ bottom: '20px', right: '20px' }} // Position at bottom-right with custom styles
        className="custom-floating-whatsapp-class" 
        buttonStyle={{ backgroundColor: "#25D366", color: "#fff" }} 
        buttonClassName="custom-button-class"
        onClick={(event) => console.log("WhatsApp button clicked", event)}
        onSubmit={(event, formValue) => console.log("Message submitted", formValue)}
        onClose={() => console.log("Chatbox closed")}
        onNotification={() => console.log("Notification triggered")} 
        onLoopDone={() => console.log("Notification loop done")} 
      />

    </>
  );
}

export default App;
