import React from "react";
import { Link } from "react-router-dom";

import Tagline from "../../components/tagline";
import Navbar from "../../components/navbar";
import About from "../../components/about";
import Client from "../../components/client";
import Blogs from "../../components/blogs";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";

import bg2 from "../../assets/images/bg/a.jpg";
import bg3 from "../../assets/images/bg/b.jpg";
import map from "../../assets/images/map-plane.png";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules";
import "swiper/swiper-bundle.css";
import Card from "../../components/card";
import Services from "../../components/services";
import Stats from "../../components/Stats";
import WhyUs from "../../components/WhyUs";

export default function IndexTwo() {
  return (
    <>
      <Tagline />
      <Navbar
        navclass="defaultscroll is-sticky tagline-height"
        navlight={true}
        manuclass="justify-end nav-light"
      />

      <section className="swiper-slider-hero relative block h-screen" id="home">
        <div className="swiper-container absolute end-0 top-0 w-full h-full">
          <Swiper
            className="swiper-wrapper"
            loop={true}
            speed={2000}
            autoplay={{ delay: 6500 }}
            effect="flip"
            modules={[Autoplay, EffectFade]}
          >
            <SwiperSlide className="swiper-slide flex items-center overflow-hidden">
              <div
                className="slide-inner absolute end-0 top-0 w-full h-full slide-bg-image flex items-center bg-center"
                style={{ backgroundImage: `url(${bg2})` }}
              >
                <div className="absolute inset-0 bg-black/70"></div>
                <div className="container relative">
                  <div className="grid grid-cols-1">
                    <div className="text-center">
                      <img src={map} className="mx-auto w-[300px]" alt="" />
                      <h1 className="font-bold text-white lg:leading-normal leading-normal text-4xl lg:text-6xl mb-6 mt-5">
                        Find the best <br /> Universities Abroad
                      </h1>
                      <p className="text-white/70 text-xl max-w-xl mx-auto">
                      Explore top international universities to match your academic goals. Get insights on prestigious institutions, programs, and admission details to choose the perfect fit for your education.
                      </p>

                      <div className="mt-6">
                        <Link
                          to=""
                          className="py-2 px-5 inline-block tracking-wide align-middle duration-500 text-base text-center bg-red-500 text-white rounded-md"
                        >
                          See More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide className="swiper-slide flex items-center overflow-hidden">
              <div
                className="slide-inner absolute end-0 top-0 w-full h-full slide-bg-image flex items-center bg-center"
                style={{ backgroundImage: `url(${bg3})` }}
              >
                <div className="absolute inset-0 bg-black/70"></div>
                <div className="container relative">
                  <div className="grid grid-cols-1">
                    <div className="text-center">
                      <img src={map} className="mx-auto w-[300px]" alt="" />
                      <h1 className="font-bold text-white lg:leading-normal leading-normal text-4xl lg:text-6xl mb-6 mt-5">
                        Discover Your Next <br /> University Placement Abroad 
                      </h1>
                      <p className="text-white/70 text-xl max-w-xl mx-auto">
                      Find the perfect university abroad with our expert assistance. We’ll help you choose the right institution and guide you through the application process for a seamless academic journey. Start exploring global opportunities today!
                      </p>

                      <div className="mt-6">
                        <Link
                          to=""
                          className="py-2 px-5 inline-block tracking-wide align-middle duration-500 text-base text-center bg-red-500 text-white rounded-md"
                        >
                          See More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      <Stats />

      <section className="relative md:pb-24 pb-16 overflow-hidden">
      <WhyUs />
        <About />
        <div className="container relative md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
            Your Trusted Educational Consultant
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto">
            Our educational consultant is your dedicated guide in the pursuit of academic success. With a wealth of expertise and a commitment to your educational journey, we provide tailored solutions and invaluable insights to help you achieve your goals. Whether it's selecting the right program, navigating the admission process, or planning your educational path, our educational consultant is here to empower and support you every step of the way.
            </p>
          </div>
        </div>
        <Card 
        />

        <Client />

        <Blogs />
      </section>
      <Footer />
      <Switcher />
    </>
  );
}
